"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProxyOFT__factory = exports.OFT__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var OFT__factory_1 = require("./OFT__factory");
Object.defineProperty(exports, "OFT__factory", { enumerable: true, get: function () { return OFT__factory_1.OFT__factory; } });
var ProxyOFT__factory_1 = require("./ProxyOFT__factory");
Object.defineProperty(exports, "ProxyOFT__factory", { enumerable: true, get: function () { return ProxyOFT__factory_1.ProxyOFT__factory; } });
